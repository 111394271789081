export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "ArchiveIcon",
  },
  {
    title: "Task Manage",
    icon: "ListIcon",
    children: [
      {
        title: "Default Task",
        route: "default-tasks",
      },
      {
        title: "Default Main Task",
        route: "default-main-tasks",
      },
    ],
  },
  {
    title: "Project",
    icon: "FolderIcon",
    children: [
      {
        title: "Projects",
        route: "projects",
      },
      {
        title: "Todo",
        route: "todo",
      },
      {
        title: "Check List",
        route: "check-list",
      },
    ],
  },
];
